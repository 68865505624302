var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-scale-transition',[_c('BaseForm',{attrs:{"apiActions":"company/company.php","backRoute":"/companylist","newRecordTitle":"Novo poduzeće","listRefreshAction":"company_list_needs_refresh","formData":[
      {
        title: 'OSNOVNI PODACI',
        rows: [
          {
            id: 1,
            fields: [
              {
                label: 'Naziv',
                type: 'text',
                name: 'name'
              }
            ]
          },
          {
            id: 2,
            fields: [
              {
                label: 'Adresa',
                type: 'text',
                name: 'address'
              },
              {
                label: 'Grad',
                type: 'text',
                name: 'city'
              },
              {
                label: 'Poštanski broj',
                type: 'text',
                name: 'postal_code'
              },
              {
                label: 'Država',
                type: 'country',
                name: 'country'
              }
            ]
          }
        ]
      },
      {
        title: 'POSLOVNI PODACI',
        rows: [
          {
            id: 3,
            fields: [
              {
                label: 'OIB',
                type: 'oib',
                name: 'oib'
              },
              {
                label: 'IBAN',
                type: 'text',
                name: 'iban'
              },
              {
                label: 'E-mail',
                type: 'email',
                name: 'email'
              },
              {
                label: 'Koristi e-račun',
                type: 'toggle',
                name: 'e_invoice'
              }
            ]
          },
          {
            id: 4,
            fields: [
              {
                label: 'Telefon 1',
                type: 'phone',
                name: 'phone'
              },
              {
                label: 'Telefon 2',
                type: 'phone',
                name: 'phone1'
              },
              {
                label: 'Fax',
                type: 'phone',
                name: 'fax'
              },
              {
                label: 'Mobitel',
                type: 'phone',
                name: 'mobile'
              }
            ]
          },
          {
            id: 5,
            fields: [
              {
                label: 'Web stranica',
                type: 'text',
                name: 'url'
              },
              {
                label: 'Kratki naziv',
                type: 'text',
                name: 'short_name'
              },
              {
                label: 'Matični broj',
                type: 'text',
                name: 'mb_num'
              },
              {
                label: 'E-mail za e-račun',
                type: 'text',
                name: 'einvoice_email'
              }
            ]
          },
          {
            id: 205,
            fields: [
              {
                label: 'Računovodstveni kontakti',
                type: 'text',
                name: 'finance_contact'
              }
            ]
          },
          {
            id: 206,
            fields: [
              {
                label: 'Sponzorski kontakti',
                type: 'text',
                name: 'sponsor_contact'
              }
            ]
          }
        ]
      },
      {
        title: 'HRO CIGRE ČLANARINA',
        rows: [
          {
            id: 20,
            fields: [
              {
                label: 'K',
                type: 'membershipinfo'
              }
            ]
          },
          {
            id: 6,
            fields: [
              {
                label: 'Plaća članarinu',
                type: 'toggle',
                name: 'membership_pays'
              },
              {
                label: 'Vrsta članarine',
                type: 'membershipname',
                name: 'membership_name'
              },
              {
                label: 'Popust (%)',
                type: 'text',
                name: 'membership_discount'
              },
              {
                label: 'Članski broj',
                type: 'text',
                name: 'membership_regnum'
              }
            ]
          },
          {
            id: 108,
            fields: [
              {
                label: 'Ponude ne trebaju po org. jedinicama',
                type: 'toggle',
                name: 'membership_no_units'
              }
            ]
          }
        ]
      },
      {
        title: 'MEĐUNARODNA ČLANARINA',
        rows: [
          {
            id: 21,
            fields: [
              {
                label: 'MK',
                type: 'membershipinfo'
              }
            ]
          },
          {
            id: 7,
            fields: [
              {
                label: 'Plaća članarinu',
                type: 'toggle',
                name: 'i_membership_pays'
              },
              {
                label: 'Vrsta članarine',
                type: 'membershipname',
                name: 'i_membership_name'
              },
              {
                label: 'Popust (%)',
                type: 'text',
                name: 'i_membership_discount'
              },
              {
                label: 'Članski broj',
                type: 'text',
                name: 'i_membership_regnum'
              }
            ]
          },
          {
            id: 8,
            fields: [
              {
                label: 'Ponude ne trebaju po org. jedinicama',
                type: 'toggle',
                name: 'i_membership_no_units'
              }
            ]
          }
        ]
      },
      {
        title: 'OSTALI PODACI',
        rows: [
          {
            id: 9,
            fields: [
              {
                label: 'Napomena',
                type: 'text',
                name: 'note'
              }
            ]
          },
          {
            id: 10,
            fields: [
              {
                label: 'Ažurirano',
                type: 'text',
                name: 'last_update',
                disabled: true
              },
              {
                label: 'Kreirano',
                type: 'text',
                name: 'created',
                disabled: true
              },
              {
                label: 'Brisano',
                type: 'text',
                name: 'deleted',
                disabled: true
              }
            ]
          }
        ]
      }
    ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }